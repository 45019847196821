import React from 'react'
import { usePutRequest } from '../hooks/request'
import { MANUFACTURER_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import ManufacturerForm from './ManufacturerForm'

export default function ManufacturerUpdate({ onCancel, onSuccess, item }) {
    const manufacturerUpdate = usePutRequest({ url: MANUFACTURER_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await manufacturerUpdate.request({ data })

        if (success) {
            onSuccess()
            showMessage('Успешно изменена', 'is-success')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить Производитель</b></h1>

            <ManufacturerForm
                loading={manufacturerUpdate.loading}
                initialValues={item}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
