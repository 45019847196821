import React from 'react'
import { Link } from 'react-router-dom'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import SupplierItemUpdate from './SupplierItemUpdate'
import { getCurrency, getPricedType } from '../utils/types'
import { getDateTime } from '../utils/date'
import { format } from '../utils/number'
import { useDeleteRequest } from '../hooks/request'
import { SUPPLIER_ITEM_DETAIL } from '../urls'

export default function SupplierItemItem({ item, groupName, supplierItems, userList, territory, index }) {
    const [showUpdateModal, setShowUpdateModal] = useModal(
        <SupplierItemUpdate
            groupName={groupName}
            supplierItems={supplierItems}
            userList={userList}
            item={item}
            hideModal={() => setShowUpdateModal()}
            territory={territory}
        />,
    )

    const productRemove = useDeleteRequest({ url: SUPPLIER_ITEM_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            supplierItems.request()
        }
    }

    return (
        <tr key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">{item.reasonablyPriced ? getPricedType(item.reasonablyPriced) : null}</td>
            <td className="is-size-6">{item.comment}</td>
            <td className="is-size-6">{getDateTime(item.createdAt)}</td>
            <td className="is-size-6">{item.payment ? getCurrency(item.payment) : null}</td>
            <td className="is-size-6">{item.paymentType === 'coming' ? format(item.amount) : parseFloat(0.0)}</td>
            <td className="is-size-6">{item.paymentType === 'consumption' ? format(item.amount) : parseFloat(0.0)}</td>
            <td className="has-text-right">
                <div className="is-pulled-right">
                    <Link to={`/documents-in/preview/${item?.documents}`}>
                        <Button className="button is-info mr-2 is-outlined is-small" icon="eye" />
                    </Link>
                </div>
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success is-outlined is-small"
                    icon="pencil-outline" />
                <Button
                    onClick={onDelete}
                    className="button is-danger mr-2 is-outlined is-small"
                    icon="trash" />

            </td>
        </tr>
    )
}
