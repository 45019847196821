import { isEmpty } from 'lodash'
import queryString from 'querystring'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'aphrodite'

import { format } from '../utils/number'
import { useModal } from '../hooks/modal'
import Layout from '../components/Layout'
import Search from '../components/Search'
import { useMessage } from '../hooks/message'
import Table from '../components/common/Table'
import Button from '../components/common/Button'
import BorrowItem from '../components/BorrowItem'
import { useQueryParams } from '../hooks/queryString'
import ClientCreate from '../components/ClientCreate'
import { useCustomModal } from '../hooks/customModal'
import DateFilter from '../components/common/DateFilter'
import PaymentCreate from '../components/payment/paymentCreate'
import { useGetRequest, useLoad, usePostRequest } from '../hooks/request'
import { BORROW_LIST,
    CLIENT_EXPORT,
    CLIENT_LIST,
    domain,
    PRODUCT_EXPORT,
    SEND_MESSAGE_SELECT,
    TOTAL_BORROW_LIST } from '../urls'
import BorrowTabs from '../components/BorrowTabs'

export default function Borrow() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const clients = useLoad({ url: CLIENT_LIST, params: { ...params, page, type: 'active' } }, [params, page])
    const productList = clients.response ? clients.response.results : []
    const borrows = useLoad({ url: BORROW_LIST, params: { page, ...params, status: 'active' } }, [params, page])

    const [showPayModal, hidePayModal] = useCustomModal(<PaymentCreate clients={clients} borrows={borrows} onCancel={() => hidePayModal()} />, styles.modal)

    const [showCreateModal, setShowCreateModal] = useModal(<ClientCreate clients={clients} onCancel={() => setShowCreateModal()} />)

    const [checkedItems, setCheckedItems] = useState([])
    const handleCheckboxChange = (itemId, isChecked) => {
        setCheckedItems((prevState) => {
            if (isChecked) return [...prevState, itemId]

            return prevState.filter((id) => id !== itemId)
        })
    }

    const [showMessage] = useMessage()
    const send = usePostRequest({ url: SEND_MESSAGE_SELECT })

    async function onSend() {
        const { success } = await send.request({ data: { client_ids: checkedItems } })
        if (success) {
            showMessage('Сизнинг sms хабарингиз муваффақиятли юборилди', 'is-success')
            setCheckedItems([])
            clients.request()
        }
    }

    const total = useLoad({ url: TOTAL_BORROW_LIST, params: { ...params } })

    // const sendMessage = useGetRequest({ url: ALL_SEND_MESSAGE })

    /*
    async function onSendAll() {
        const { success } = await sendMessage.request()
        if (success) showMessage('Сизнинг sms хабарингиз  муваффақиятли юборилди', 'is-success')
    }
*/

    const excel = useGetRequest({ url: CLIENT_EXPORT })
    const excelItem = excel.response ? excel.response.fileUrl || excel.response.file : null

    useEffect(() => {
        if (excelItem && !isEmpty(excelItem)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItem}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItem])
    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])
    const history = useHistory()
    const { request: postExcel } = usePostRequest({ url: '/product/file_upload_client' })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            history.push('?')
        }
        if (error) showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
    }

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout onSend={onSend} showCreateModal={showCreateModal}>

            <div className="is-flex">
                <Search onSearch={onSearch} />

                <DateFilter className="ml-2" name="date" />

                <Button onClick={showPayModal} icon="cash-outline" text="Платить" className="is-info ml-2 is-outlined" />

                <Button text="Выберите" icon="send-outline" className=" button is-link is-outlined ml-2" onClick={onSend} />

                {/* <Button loading={sendMessage.loading} onClick={onSendAll} icon="paper-plane-outline" className=" button is-link is-outlined ml-2" text="Всем SMS" /> */}

                <Button onClick={excel.request} icon="cloud-upload-outline" className=" button is-link is-outlined ml-2" text="Ехcел" />

                <label style={{ position: 'relative' }}>
                    <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                    <input onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                </label>

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
            </div>

            <div className="pt-3 mb-3">
                <span className="tag is-link is-light is-medium mr-2">
                    <h3 className="is-size-6 mr-4">Все Долги (Сум):</h3>
                    <p className="is-size-6" style={{ fontWeight: '600' }}>
                        {format(total.response ? total.response.totalAmountSum : 0)} Сум
                    </p>
                </span>
                <span className="tag is-link is-light is-medium">
                    <h3 className="is-size-6 mr-4">Все Долги (USD):</h3>
                    <p className="is-size-6" style={{ fontWeight: '600' }}>
                        {format(total.response ? total.response.totalAmountUsd : 0)} USD
                    </p>
                </span>
            </div>

            <BorrowTabs />

            <div>
                <Table
                    loading={clients.loading}
                    items={productList}
                    columns={{ index: 'ID', name: 'Имя', address: 'Номер телефона', price: 'Цена', actisadons: 'Выберите', actidssadons: 'Последней отправки', action: '' }}
                    totalCount={clients.response ? clients.response.count : 0}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item) => <BorrowItem onCheckboxChange={handleCheckboxChange} clients={clients} products={clients} key={item.id} item={item} />}
                />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' }, emptyImg: { width: '330px', height: '300px' }, loader: { marginTop: '2rem' }, modal: { width: 800 } })
