import React, { useState } from 'react'
import Layout from '../../components/Layout'
import SettingsTabs from '../../components/settings/SettingsTabs'
import Card from '../../components/common/Card'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import CurrencyCreate from '../../components/CurrencyCreate'
import { useModal } from '../../hooks/modal'
import { useLoad } from '../../hooks/request'
import CurrencyItem from '../../components/CurrencyItem'
import { BRAND_LIST,
    CAR_LIST,
    CATEGORY_LIST,
    CURRENCY_LIST,
    MANUFACTURER_LIST,
    PRODUCT_LOCATION_LIST } from '../../urls'
import CategoryItem from '../../components/CategoryItem'
import BrandItem from '../../components/BrandItem'
import BrandCreate from '../../components/BrandCreate'
import ProductLocationCreate from '../../components/ProductLocationCreate'
import CarCreate from '../../components/CarCreate'
import CategoryCreate from '../../components/CategoryCreate'
import ProductLocationItem from '../../components/ProductLocationItem'
import ManufacturerItem from '../../components/ManufacturerItem'
import ManufacturerCreate from '../../components/ManufacturerCreate'
import CarItem from '../../components/CarItem'
import { useQueryParams } from '../../hooks/queryString'

export default function Currency() {
    const { params } = useQueryParams()

    const [pageCurrency, setPageCurrency] = useState(1)
    const [pageCategory, setPageCategory] = useState(1)
    const [pageBrand, setPageBrand] = useState(1)
    const [pageProductLocation, setPageProductLocation] = useState(1)
    const [pageCar, setPageCar] = useState(1)
    const [pageManufacturer, setPageManufacturer] = useState(1)

    const currency = useLoad({ url: CURRENCY_LIST, params: { page: pageCurrency, ...params } }, [pageCurrency, params])
    const category = useLoad({ url: CATEGORY_LIST, params: { page: pageCategory, ...params } }, [pageCategory, params])
    const brand = useLoad({ url: BRAND_LIST, params: { page: pageBrand, ...params } }, [pageBrand, params])
    const productLocation = useLoad({
        url: PRODUCT_LOCATION_LIST,
        params: { page: pageProductLocation, ...params },
    }, [pageProductLocation, params])
    const car = useLoad({ url: CAR_LIST, params: { page: pageCar, ...params } }, [pageCar, params])
    const manufacturer = useLoad({
        url: MANUFACTURER_LIST,
        params: { page: pageManufacturer, ...params },
    }, [pageManufacturer, params])

    const [showCreateCurrencyModal, hideCreateCurrencyModal] = useModal(
        <CurrencyCreate onSuccess={() => {
            hideCreateCurrencyModal()
            currency.request()
        }} onCancel={() => hideCreateCurrencyModal()} />,
    )
    const [showCreateCarModal, hideCreateCarModal] = useModal(
        <CarCreate onSuccess={() => {
            hideCreateCarModal()
            car.request()
        }} onCancel={() => hideCreateCarModal()} />,
    )
    const [showCreateBrandModal, hideCreateBrandModal] = useModal(
        <BrandCreate onSuccess={() => {
            hideCreateBrandModal()
            brand.request()
        }} onCancel={() => hideCreateBrandModal()} />,
    )
    const [showCreateProductLocationModal, hideCreateProductLocationModal] = useModal(
        <ProductLocationCreate onSuccess={() => {
            hideCreateProductLocationModal()
            productLocation.request()
        }} onCancel={() => hideCreateProductLocationModal()} />,
    )
    const [showCreateCategoryModal, hideCreateCategoryModal] = useModal(
        <CategoryCreate onSuccess={() => {
            hideCreateCategoryModal()
            category.request()
        }} onCancel={() => hideCreateCategoryModal()} />,
    )
    const [showCreateManufacturerModal, hideCreateManufacturerModal] = useModal(
        <ManufacturerCreate onSuccess={() => {
            hideCreateManufacturerModal()
            manufacturer.request()
        }} onCancel={() => hideCreateManufacturerModal()} />,
    )
    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div className="columns">
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Бренд</div>

                            <div className="column is-narrow">
                                <Button
                                    onClick={showCreateBrandModal}
                                    text="Добавить"
                                    icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            onPageChange={setPageBrand}
                            activePage={pageBrand}
                            loading={brand.loading}
                            totalCount={brand.response ? brand.response.count : []}
                            items={brand.response ? brand.response.results : []}
                            renderItem={(item, index) => (
                                <BrandItem
                                    index={index}
                                    brand={brand}
                                    item={item}
                                    key={item.id}
                                    onDelete={brand.request}
                                    onUpdate={brand.request}
                                    status={brand}
                                />
                            )} />

                    </Card> <br />
                </div>
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Категория</div>

                            <div className="column is-narrow">
                                <Button
                                    onClick={showCreateCategoryModal}
                                    text="Добавить"
                                    icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            totalCount={category.response ? category.response.count : 0}
                            onPageChange={setPageCategory}
                            activePage={pageCategory}
                            loading={category.loading}
                            items={category.response ? category.response.results : []}
                            renderItem={(item, index) => (
                                <CategoryItem
                                    index={index}
                                    category={category}
                                    item={item}
                                    key={item.id}
                                    onDelete={category.request}
                                    onUpdate={category.request}
                                />
                            )} />

                    </Card> <br />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Название автомобиля</div>

                            <div className="column is-narrow">
                                <Button
                                    onClick={showCreateCarModal}
                                    text="Добавить"
                                    icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            totalCount={car.response ? car.response.count : []}
                            onPageChange={setPageCar}
                            activePage={pageCar}
                            loading={car.loading}
                            items={car.response ? car.response.results : []}
                            renderItem={(item, index) => (
                                <CarItem
                                    index={index}
                                    car={car}
                                    item={item}
                                    key={item.id}
                                    onDelete={car.request}
                                    onUpdate={car.request}
                                    status={car}
                                />
                            )} />

                    </Card> <br />
                </div>
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Название Производитель</div>

                            <div className="column is-narrow">
                                <Button
                                    onClick={showCreateManufacturerModal}
                                    text="Добавить"
                                    icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            onPageChange={setPageManufacturer}
                            activePage={pageManufacturer}
                            loading={manufacturer.loading}
                            totalCount={manufacturer.response ? manufacturer.response.count : []}
                            items={manufacturer.response ? manufacturer.response.results : []}
                            renderItem={(item, index) => (
                                <ManufacturerItem
                                    index={index}
                                    manufacturer={manufacturer}
                                    item={item}
                                    key={item.id}
                                    onDelete={manufacturer.request}
                                    onUpdate={manufacturer.request}
                                    status={manufacturer}
                                />
                            )} />
                    </Card> <br />
                </div>

            </div>

            <div className="columns">

                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Местоположение продукта</div>

                            <div className="column is-narrow">
                                <Button
                                    onClick={showCreateProductLocationModal}
                                    text="Добавить"
                                    icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>
                        <Table
                            totalCount={productLocation.response ? productLocation.response.count : 0}
                            onPageChange={setPageProductLocation}
                            activePage={pageProductLocation}
                            loading={productLocation.loading}
                            items={productLocation.response ? productLocation.response.results : []}
                            renderItem={(item, index) => (
                                <ProductLocationItem
                                    index={index}
                                    productLocation={productLocation}
                                    item={item}
                                    key={item.id}
                                    onDelete={productLocation.request}
                                    onUpdate={productLocation.request}
                                    status={productLocation}
                                />
                            )} />
                    </Card>
                    <br />
                </div>
                <div className="column">
                    <Card>
                        <div className="columns">
                            <div className="is-size-4 column">Доллар курс</div>

                            <div className="column is-narrow">
                                <Button
                                    onClick={showCreateCurrencyModal}
                                    text="Добавить"
                                    icon="add-outline"
                                    className="is-pulled-right is-link is-outlined" />
                            </div>
                        </div>

                        <Table
                            onPageChange={setPageCurrency}
                            totalCount={currency.response ? currency.response.length : 0}
                            activePage={pageCurrency}
                            loading={currency.loading}
                            items={currency.response ? currency.response : []}
                            renderItem={(item, index) => (
                                <CurrencyItem
                                    index={index}
                                    currency={currency}
                                    item={item}
                                    key={item.id}
                                    onDelete={currency.request}
                                    onUpdate={currency.request}
                                    status={currency}
                                />
                            )} />

                    </Card> <br />

                </div>

            </div>
        </Layout>
    )
}
