import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { currency } from '../utils/types'

export default function BorrowForm({ onCancel, onSubmit, initialValues, loading }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            currency: 'sum',
            description: '',
            amount: '',
            termDate: '',
            ...initialValues,
        }}>
            <Form>
                <Input
                    name="amount"
                    type="number" label="Цена" validate={required} />
                <Select optionValue="value" optionLabel="name" empty options={currency} name="currency"
                    label="Выберите тип оплаты" />
                <Input name="description" component="textarea" label="Описание" />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
