import React from 'react'
import { PRODUCT_DETAIL } from '../../urls'
import { usePutRequest } from '../../hooks/request'
import { useMessage } from '../../hooks/message'
import ImageUploadForm from './ImageUploadForm'

export default function ImageUploadModal({ hideModal, item, products }) {
    const [showMessage] = useMessage()
    const productUpdate = usePutRequest({ url: PRODUCT_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success, error } = await productUpdate.request({
            data,
        })
        if (success) {
            products.request()
            showMessage('Успешно обновлен', 'is-success')
            hideModal()
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Изменить товар</b>
            </h1>

            <ImageUploadForm
                loading={productUpdate.loading}
                onCancel={hideModal}
                initialValues={{
                    ...item,
                }}
                onSubmit={onSubmit}
            />
        </div>
    )
}
