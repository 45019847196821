import React from 'react'
import { Form, Formik } from 'formik'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { usePostRequest } from '../hooks/request'
import { SIGN_IN } from '../urls'
import { email, required, validator } from '../utils/validators'
import Password from '../components/common/Password'
import { isAuthenticated, signin } from '../utils/auth'
import LayoutAuth from '../components/LayoutAuth'

export default function Login() {
    const history = useHistory()
    const signIn = usePostRequest({ url: SIGN_IN })
    const user = localStorage.getItem('user')
    if (isAuthenticated()) return <Redirect to={user.admin ? '/orders' : '/products'} />

    async function onSubmit(data) {
        const { response, success } = await signIn.request({ data })
        if (success) signin(response, history)
    }

    const sideBar = () => (
        <div>
            <h2 className="is-size-4 has-text-weight-bold">Добро пожаловать!</h2>
            <p className="is-size-5">
                Эта CRM-система разработана
                <a className="is-uppercase ml-2 has-text-white" href="https://craftsoft.uz/">
                    craftsoft.uz
                </a>
            </p>

            <p className="is-size-5">
                Номер телефона:
                <a className="has-text-white ml-2" href="tel:+998994204488">
                    +998 (99) 420 44-88
                </a>
            </p>

            <br />
        </div>
    )

    return (
        <LayoutAuth sidebar={sideBar()}>
            <div id="login" className="has-text-centered mb-4">
                <h2 className="is-size-6 has-text-weight-medium mb-4">
                    нажмите кнопку, чтобы переключиться в <br /> режим телефона
                </h2>
            </div>

            <div id="login" className="has-text-centered m-5">
                <a style={{ border: '1px solid black', padding: '10px', borderRadius: 5 }} className="ml-2 is-size-7 has-text-weight-bold mb-4" href="https://mobile.zapsklad.uz">
                    Переключиться на мобильный ☎️
                </a>
            </div>

            <div id="login-title-panel" className="has-text-centered mb-4 mt-5">
                <h2 className="is-size-4 has-text-weight-bold mb-4">Войдите в систему</h2>
            </div>

            <Formik onSubmit={onSubmit} initialValues={{ email: '', password: '' }}>
                <Form>
                    <ServerError error={signIn.error} />
                    <Input name="email" icon="ion-md-mail" validate={validator(required, email)} placeholder="Email" />
                    <Password name="password" icon="ion-md-key" validate={required} placeholder="Password" />

                    <div className="field">
                        <div className="control">
                            <Button loading={signIn.loading} className="button is-link is-medium is-fullwidth" text="Войти" type="submit" />
                        </div>
                    </div>
                </Form>
            </Formik>
        </LayoutAuth>
    )
}
