import React from 'react'
import { CATEGORY_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import CategoryUpdate from './CategoryUpdate'
import { useModal } from '../hooks/modal'
import { getDateTime } from '../utils/date'
import Loader from './common/Loader'

export default function CategoryItem({ category, item, index }) {
    const categoryRemove = useDeleteRequest({ url: CATEGORY_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await categoryRemove.request()
            category.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <CategoryUpdate
            category={category}
            item={item}
            onCancel={() => hideUpdateModal()}
        />,
    )

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                {item.name}
            </td>

            <td>
                {getDateTime(item.createdAt)}
            </td>

            <td>
                <div className="is-pulled-right">
                    {!categoryRemove.loading ? (
                        <i onClick={onDelete} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />}

                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />

                </div>
            </td>
        </tr>
    )
}
