import React from 'react'
import Button from './common/Button'
import { useDeleteRequest } from '../hooks/request'
import { DOCUMENTS_PRODUCTS_DETAIL } from '../urls'
import { format } from '../utils/number'

export default function DocumentsPreviewItem({ item, sum, docProducts, documentDetail }) {
    const itemDelete = useDeleteRequest({ url: DOCUMENTS_PRODUCTS_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await itemDelete.request()
            docProducts.request()
            documentDetail.request()
        }
    }

    return (
        <tr key={item?.id}>
            <td>{item.nomenclature?.name}</td>
            <td>{item.nomenclature.codeContent}</td>
            <td>{item?.count}</td>
            <td>{format(item?.price)}</td>
            <td>{format(item?.priceTransfer)}</td>
            <td>{format(item?.priceSale)}</td>
            <td className="has-text-centered">{sum ?? ''}</td>
            <td>
                <Button onClick={onDelete} icon="trash-outline" className="is-danger is-outlined is-size-7" />
            </td>
        </tr>
    )
}
