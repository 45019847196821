import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'aphrodite'
import { getCurrency, getMeasurement } from '../../utils/types'
import { useDeleteRequest, usePutRequest } from '../../hooks/request'
import { NOMENCLATURE_DETAIL } from '../../urls'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'
import ProductUpdate from '../../components/ProductUpdate'

// Debounce function
function debounce(fn, delay) {
    let timeoutId
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}

export default function PromotionColumn({ item, index, products }) {
    const productUpdate = usePutRequest({ url: NOMENCLATURE_DETAIL.replace('{id}', item.id) })

    const [price, setPrice] = useState(item?.price)
    const [priceSale, setPriceSale] = useState(item?.priceSale)
    const [priceTransfer, setPriceTransfer] = useState(item?.priceTransfer)
    const [count, setCount] = useState(item?.count || 0) // New state for count

    // Debounced updateProduct function
    const debouncedUpdateProduct = debounce(async (field, value) => {
        try {
            await productUpdate.request({ data: { [field]: value } })
        } catch (error) {
            console.log(error, 'error')
        }
    }, 500)

    // Clear timeout on component unmount to avoid memory leaks
    useEffect(() => () => {
        debouncedUpdateProduct.cancel?.()
    }, [debouncedUpdateProduct])

    const handleInputChange = (e, type) => {
        const value = e?.target?.value
        let field = ''

        switch (type) {
        case 'price':
            field = 'price'
            setPrice(value)
            break
        case 'priceSale':
            field = 'priceSale'
            setPriceSale(value)
            break
        case 'priceTransfer':
            field = 'priceTransfer'
            setPriceTransfer(value)
            break
        case 'count': // New case for count
            field = 'count'
            setCount(value)
            break
        default:
            return
        }

        debouncedUpdateProduct(field, value)
    }

    console.log(item)
    const productRemove = useDeleteRequest({ url: NOMENCLATURE_DETAIL.replace('{id}', item?.id) })
    const [showUpdateModal, setShowUpdateModal] = useModal(<ProductUpdate products={products} item={item?.products}
        hideModal={() => setShowUpdateModal()} />, styles.modal)

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            // await products.request()
        }
    }
    return (
        <tr className="is-size-6" key={item?.id || index}>
            <td>{item?.products?.name}</td>

            <td>
                {item?.products?.car?.length > 0 ? (
                    item?.products.car.map((car) => (
                        <span key={car.name} className="is-light tag is-link mr-2 mb-2">
                            {car.name}
                        </span>
                    ))
                ) : (
                    <span>Mashina topilmadi</span>
                )}
            </td>
            <td>{item?.products?.selectBrand?.name}</td>
            <td>{item?.products?.selectManufacturer?.name}</td>
            <td>
                <div className="is-flex">
                    <input
                        style={{ width: 100 }}
                        value={count || ''}
                        type="number"
                        className="is-size-7 input is-small mr-2" // Input for count
                        name={`products.${index}.count`}
                        onChange={(e) => handleInputChange(e, 'count')}
                    />
                    {getMeasurement(item?.measurement)}
                </div>
            </td>
            <td>
                <input
                    value={price || ''}
                    type="number"
                    className="is-size-7 input is-small"
                    name={`products.${index}.price`}
                    onChange={(e) => handleInputChange(e, 'price')}
                />
            </td>
            <td>
                <input
                    value={priceTransfer || ''}
                    type="number"
                    className="is-size-7 input is-small"
                    name={`products.${index}.priceTransfer`}
                    onChange={(e) => handleInputChange(e, 'priceTransfer')}
                />
            </td>
            <td>
                <input
                    value={priceSale || ''}
                    type="number"
                    className="is-size-7 input is-small"
                    name={`products.${index}.priceSale`}
                    onChange={(e) => handleInputChange(e, 'priceSale')}
                />
            </td>

            <td>{getCurrency(item?.paymentType)}</td>
            <td className="is-size-6">{item?.products?.category ? item?.products?.selectCategory?.name : 'Другое'}</td>
            <td className="is-size-6">{item?.products?.selectProductLocation ? item?.products?.selectProductLocation?.name : 'Другое'}</td>

            <td>
                <div style={{ display: 'flex', alignItems: 'center' }} className="is-pulled-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small"
                        icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: { ':nth-child(1n) > td': { lineHeight: 1 } },
    center: { display: 'flex', alignItems: 'center' },
    td1: { display: 'flex', alignItems: 'center', gap: '5px', lineHeight: 1, cursor: 'pointer' },
    td1Img: { width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' },
    td1Right: {},
    td1RightTitle: { fontFamily: 'GilroySemi', color: '#333333', lineHeight: '19px' },
    td1RightSubtitle: {
        marginTop: '1px',
        fontFamily: 'Gilroy',
        fontSize: '14px',
        color: 'rgba(51, 51, 51, 0.7)',
        lineHeight: '16px',
    },
    industries: { display: 'flex', whiteSpace: 'nowrap', flexWrap: 'wrap' },
    modal: { width: 1000 },
    icon: { fontSize: '20px', padding: 10 },
})
