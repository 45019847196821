import React from 'react'
import { NavLink } from 'react-router-dom'
import { useModal } from '../hooks/modal'
import { useDeleteRequest } from '../hooks/request'
import { SUPPLIER_DETAIL } from '../urls'
import Button from './common/Button'
import SupplierUpdate from './SupplierUpdate'
import { format } from '../utils/number'

export default function SupplierItem({ item, userList, territory, index, suppliers, onClick, selectedSupplier }) {
    const [showUpdateModal, setShowUpdateModal] = useModal(<SupplierUpdate suppliers={suppliers} userList={userList} item={item} hideModal={() => setShowUpdateModal()} territory={territory} />)

    const productRemove = useDeleteRequest({ url: SUPPLIER_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            suppliers.request()
        }
    }

    const backgroundColor = { backgroundColor: selectedSupplier?.id === item?.id && '#D3D3D3' }
    const textColorSum = { color: item?.sumComingTotal - item?.sumConsumptionTotal <= 0 ? 'red' : 'green' }
    const textColorUsd = { color: item?.usdComingTotal - item?.usdConsumptionTotal <= 0 ? 'red' : 'green' }

    return (
        <tr key={item.id} onClick={() => onClick(item)} style={backgroundColor}>
            <td className="is-size-6">{index + 1}</td>

            <td className="is-size-6">
                <NavLink to={`/supplier/${item.id}/checks`}>{item.name}</NavLink>
            </td>

            <td className="is-size-6">{item.phone}</td>
            <td className="is-size-6">{item.phoneDirector}</td>
            <td className="is-size-6">{item.phoneAccountant} </td>
            <td className="is-size-6">
                <p style={textColorSum}>{format(item?.sumComingTotal - item?.sumConsumptionTotal)} Сум</p>
                <p style={textColorUsd}>{format(item?.usdComingTotal - item?.usdConsumptionTotal)} USD</p>
            </td>
            <td className="">
                <div className="is-pulled-right is-flex">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
