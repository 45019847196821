import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useLoad } from '../hooks/request'
import { BRAND_LIST, CAR_LIST, CATEGORY_LIST, MANUFACTURER_LIST, PRODUCT_LOCATION_LIST } from '../urls'
import NewFilterSelect from './common/NewFilterSelect'

export default function CustomersActionsBar({ setSearchParams }) {
    const brand = useLoad({ url: BRAND_LIST, params: { size: 1000 } })
    const brandItems = brand.response ? brand.response.results : []

    const car = useLoad({ url: CAR_LIST, params: { size: 1000 } })
    const carItems = car.response ? car.response.results : []

    const productLocation = useLoad({ url: PRODUCT_LOCATION_LIST, params: { size: 1000 } })
    const productLocationOptions = productLocation.response?.results || []

    const manufacturer = useLoad({ url: MANUFACTURER_LIST, params: { size: 1000 } })
    const manufacturerList = manufacturer.response?.results || []

    const category = useLoad({ url: CATEGORY_LIST, params: { size: 1000 } })
    const categoryList = category.response ? category.response.results : []

    return (
        <main className={cn(css(styles.parent))}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, auto))', gap: '1rem', width: '100%', marginBottom: '2rem' }}>
                <NewFilterSelect setSearchParams={setSearchParams} name="brand" options={brandItems} placeholder="Бренд" />
                <NewFilterSelect setSearchParams={setSearchParams} name="car" options={carItems} placeholder="Машина" />
                <NewFilterSelect setSearchParams={setSearchParams} name="productLocation" options={productLocationOptions} placeholder="Местоположение" />
                <NewFilterSelect setSearchParams={setSearchParams} name="category" options={categoryList} placeholder="Категория" />
                <NewFilterSelect setSearchParams={setSearchParams} name="manufacturer" options={manufacturerList} placeholder="Производитель" />
            </div>
        </main>
    )
}

const styles = StyleSheet.create({ parent: {}, icon: { transform: 'rotate(90deg) scale(1.5)' }, modal: { width: 1000 } })
