import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { StyleSheet } from 'aphrodite'
import Button from '../../components/common/Button'
import { useDeleteRequest } from '../../hooks/request'
import { useModal } from '../../hooks/modal'
import ProductHistory from '../../components/ProductHistory'
import { PRODUCT_DETAIL } from '../../urls'
import { measurement } from '../../utils/position'
import PrintBarCode from '../../components/Print'
import ProductUpdate from '../../components/ProductUpdate'
import ImageUploadModal from './ImageUploadModal'

export default function ItemProduct({ check, categoryList, item, user, brandList, index, products }) {
    const productRemove = useDeleteRequest({ url: PRODUCT_DETAIL.replace('{id}', item?.id) })
    const [showDetailModal, hideDetailModal] = useModal(<ProductHistory check={check} productId={item.id} user={user} item={item} hideModal={() => hideDetailModal()} />, styles.modal)
    console.log(showDetailModal)
    const [showUpdateModal, setShowUpdateModal] = useModal(<ProductUpdate products={products} item={item} hideModal={() => setShowUpdateModal()} />, styles.modal)
    const [showUploadModal, setShowUploadModal] = useModal(
        <ImageUploadModal products={products} categoryList={categoryList} brandList={brandList} item={item} hideModal={() => setShowUploadModal()} />,
        styles.modal,
    )

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            await products.request()
        }
    }

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            {/* <td onClick={() => showDetailModal()} className="is-size-6 is-capitalized"> */}
            <td className="is-size-6 is-capitalized">{item?.name}</td>

            <td>
                {item?.car.map((car) => (
                    <span className="is-light tag is-link mr-2 mb-2">{car.name}</span>
                ))}
            </td>
            <td>{item?.selectBrand?.name}</td>
            <td>{item?.selectProductLocation?.name}</td>

            <td>{item?.selectManufacturer?.name}</td>

            <td className="is-size-6">
                {item.productCount} {measurement(item?.measurement)}
            </td>
            <td className="is-size-6">{item.barcode}</td>
            <td className="is-size-6">{item.article}</td>
            <td className="is-size-6">{item?.category ? item.selectCategory.name : 'Другое'}</td>

            <td>
                <div style={{ display: 'flex', alignItems: 'center' }} className="is-pulled-right">
                    <Button onClick={showUploadModal} className="mr-2 button is-success is-outlined is-small" icon="cloud-upload-outline" />
                    <Button onClick={handlePrint} className="mr-2 button is-link is-outlined is-small" icon="qr-code-outline" />
                    <div style={{ display: 'none' }}>
                        <PrintBarCode item={item} ref={componentRef} />
                    </div>
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: { ':nth-child(1n) > td': { lineHeight: 1 } },
    center: { display: 'flex', alignItems: 'center' },
    td1: { display: 'flex', alignItems: 'center', gap: '5px', lineHeight: 1, cursor: 'pointer' },
    td1Img: { width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' },
    td1Right: {},
    td1RightTitle: { fontFamily: 'GilroySemi', color: '#333333', lineHeight: '19px' },
    td1RightSubtitle: { marginTop: '1px', fontFamily: 'Gilroy', fontSize: '14px', color: 'rgba(51, 51, 51, 0.7)', lineHeight: '16px' },
    industries: { display: 'flex', whiteSpace: 'nowrap', flexWrap: 'wrap' },
    modal: { width: 1000 },
    icon: { fontSize: '20px', padding: 10 },
})
