import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useModal } from '../hooks/modal'
import { useDeleteRequest } from '../hooks/request'
import { NOMENCLATURE_DETAIL } from '../urls'
import { getDateTime } from '../utils/date'
import { format } from '../utils/number'
import { measurement } from '../utils/position'
import Button from './common/Button'
import PrintBarCode from './PrintBarCode'
// import ProductHistory from './ProductHistory'
import ProductUpdate from './ProductUpdate'

export default function NomenclatureHomeItem({ check, categoryList, item, user, brandList, index, products }) {
    console.log(check, user)
    // const [showDetailModal, hideDetailModal] = useModal(<ProductHistory check={check} productId={item.id} user={user} item={item} hideModal={() => hideDetailModal()} />, styles.modal)

    const productRemove = useDeleteRequest({ url: NOMENCLATURE_DETAIL.replace('{id}', item?.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            await products.request()
        }
    }

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <ProductUpdate products={products} categoryList={categoryList} brandList={brandList} item={item} hideModal={() => setShowUpdateModal()} />,
        // eslint-disable-next-line comma-dangle
        styles.modal,
    )

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    console.log(item)
    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>

            <td>{item?.name}</td>

            <td>
                {item?.products?.car.map((car) => (
                    <span className="is-light tag is-link mr-2 mb-2">{car.name}</span>
                ))}
            </td>

            <td>
                {item?.products?.selectBrand?.name}

            </td>

            <td className="is-size-6">{item?.selectSupplier?.name}</td>

            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {format(item?.usdPriceSale)} USD
                <i className={cn('icon ion-md-return-right', css(styles.icon))} />
                {format(item.sumPriceSale)} Сум
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {format(item?.usdPrice)} USD
                <i className={cn('icon ion-md-return-right', css(styles.icon))} />
                {format(item.sumPrice)} Сум
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {format(item?.usdPriceTransfer)} USD
                <i className={cn('icon ion-md-return-right', css(styles.icon))} />
                {format(item.sumPriceTransfer)} Сум
            </td>

            <td className="is-size-6">
                {item?.count} {measurement(item?.measurement)}
            </td>

            <td className="is-size-6">{item?.products?.barcode}</td>

            <td>
                {item?.products?.selectProductLocation?.name}
            </td>

            <td>
                {item?.products?.selectManufacturer?.name}
            </td>
            <td>
                {item?.products?.selectCategory?.name}
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {getDateTime(item.createdAt)}
            </td>

            <td className="">
                <div className="is-pulled-right is-flex is-flex-wrap-nowrap">
                    <Button onClick={handlePrint} className="mr-2 button is-link is-outlined is-small"
                        icon="qr-code-outline" />
                    <div style={{ display: 'none' }}>
                        <PrintBarCode item={item} ref={componentRef} />
                    </div>
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small"
                        icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: { ':nth-child(1n) > td': { lineHeight: 1 } },
    center: { display: 'flex', alignItems: 'center' },
    td1: { display: 'flex', alignItems: 'center', gap: '5px', lineHeight: 1, cursor: 'pointer' },
    td1Img: { width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' },
    td1Right: {},
    td1RightTitle: { fontFamily: 'GilroySemi', color: '#333333', lineHeight: '19px' },
    td1RightSubtitle: { marginTop: '1px', fontFamily: 'Gilroy', fontSize: '14px', color: 'rgba(51, 51, 51, 0.7)', lineHeight: '16px' },
    industries: { display: 'flex', whiteSpace: 'nowrap', flexWrap: 'wrap' },
    modal: { width: 1000 },
})
