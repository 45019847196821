import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Button from '../../components/common/Button'
import { domain } from '../../urls'

export default function ImageUploadForm({ onCancel, onSubmit, loading, initialValues }) {
    const initialImagePreview = initialValues.image && typeof initialValues.image === 'string' ? `${domain}${initialValues.image}` : null

    const [imagePreview, setImagePreview] = useState(initialImagePreview)

    return (
        <Formik
            initialValues={{ image: initialValues.image || null }}
            onSubmit={(vals, actions) => {
                const formData = new FormData()
                if (vals.image instanceof File) {
                    formData.append('image', vals.image)
                } else if (typeof vals.image === 'string') {
                    formData.append('imageUrl', vals.image)
                }

                onSubmit(formData, actions)
            }}
        >
            {({ setFieldValue }) => (
                <Form>
                    <div className="columns">
                        <div className="column">
                            {imagePreview ? (
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <img src={imagePreview} alt="Uploaded Preview" style={{ width: '100px', height: 'auto' }} />
                                    <Button
                                        onClick={() => {
                                            if (imagePreview && typeof imagePreview === 'string' && imagePreview.startsWith('blob:')) {
                                                URL.revokeObjectURL(imagePreview)
                                            }
                                            setImagePreview(null)
                                            setFieldValue('image', null)
                                        }}
                                        icon="trash-outline"
                                        className="button is-danger is-small"
                                        style={{ position: 'absolute', top: '0', right: '0' }}
                                    />
                                </div>
                            ) : (
                                <input
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={(event) => {
                                        const file = event.currentTarget.files[0]
                                        if (file) {
                                            if (imagePreview && typeof imagePreview === 'string' && imagePreview.startsWith('blob:')) {
                                                URL.revokeObjectURL(imagePreview)
                                            }

                                            const newImagePreview = URL.createObjectURL(file)
                                            setImagePreview(newImagePreview)
                                            setFieldValue('image', file)
                                        }
                                    }}
                                    disabled={loading}
                                />
                            )}
                        </div>
                    </div>
                    <div className="is-pulled-right">
                        <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" disabled={loading} />
                        &nbsp;&nbsp;
                        <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
